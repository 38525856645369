import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import DigitalLessonBundle from '../../components/DigitalLessonBundle';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const StabilityInstabilityPage = ({ data }) => (
  <Layout title="Stability and Instability">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">Stability and Instability</h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              guidSrc="25b9ff13-c439-4ded-9869-a2650eabed55"
              title="Stability and Instability"
            />
          </Column>
          <Column className="pt-3 pl-3 pr-3" size={4}>
            <p>
              Examine the complex ways that the human body self-regulates to
              maintain stability while interacting with the forces of gravity,
              and find out the difference between <i>center of gravity</i> and{' '}
              <i>center of mass</i>. 
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Video Topic Series Guide</h3>
            <p>
              The Caterpillar Foundation Video Series Guide for high school
              physics is a standards-aligned curriculum of engaging videos and
              hands-on activities designed to bring the world of STEM and
              Industry 4.0 into the classroom. Students learn the fundamentals
              of physical laws and how to problem solve by tackling real-world
              challenges in class and after-school sessions. Through a process
              of investigation, team discussion, and hands-on experiences,
              students will advance theory into applications they can see and
              touch.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-VTS-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_ES.pdf',
                    metadata: '.PDF, 418KB',
                  },
                  {
                    label: 'French',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_FR.pdf',
                    metadata: '.PDF, 436KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS ACTIVITIES **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2>Hands-On Classroom Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Discover how the Laws of Motion play a part in every single thing
              we do with these simple, easy-to-implement activations aligned to
              education standards. Students will take on a series of creative
              STEM challenges in which they put the power of force and motion
              into action.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              img="stability-image-activity1.png"
              subjects={['Classroom Activity']}
              title={'Build-a-Bridge'}
              audience="What is it that makes some structures more stable than others?"
              description="Explore the keys to strong infrastructure as students collaborate to build a working bridge and test how much weight it can handle."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-BuildABridge.pdf',
                      metadata: '.PDF, 513KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-BuildABridge_ES.pdf',
                      metadata: '.PDF, 589KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-BuildABridge_FR.pdf',
                      metadata: '.PDF, 602KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'stability-image-activity2.png'}
              subjects={['Classroom Activity']}
              title={'Finding Balance'}
              audience="What—and where—is your center of gravity?"
              description="Discover the science behind seasickness with an activity in which students design a boat and investigate how to give it stability."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-FindingBalance.pdf',
                      metadata: '.PDF, 507KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-FindingBalance_ES.pdf',
                      metadata: '.PDF, 558KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-FindingBalance_FR.pdf',
                      metadata: '.PDF, 580KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'stability-image-activity3.png'}
              subjects={['Classroom Activity']}
              title={'Roller Coaster Design'}
              audience="Would roller coasters work in a zero-gravity environment?"
              description="Unravel the power of centripetal force as students build their own marble roller coaster and work to complete a 360° loop."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-RollerCoasterDesign.pdf',
                      metadata: '.PDF, 511KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-RollerCoasterDesign_ES.pdf',
                      metadata: '.PDF, 597KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-RollerCoasterDesign_FR.pdf',
                      metadata: '.PDF, 609KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12} className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'stability-image-ASactivity.png'}
                subjects={['Subject Area']}
                title={'Stabilize Yourself'}
                className="magnus-effect"
                description="Students will explore the role of center of gravity on balance and stability by changing positions or adding objects that shift the center of gravity as they repeat this activity. Afterwards, students will how advancements in robotics have pushed industry 4.0 primarily through stabilization and how it may continue to influence industry 4.0."
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/Caterpillar-TeacherActivity-StabilizeYourself.pdf',
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                        metadata: '.PDF, 606KB',
                      },
                      {
                        label: 'Spanish',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-ES.pdf',
                        metadata: '.PDF, 718KB',
                      },
                      {
                        label: 'French',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-FR.pdf',
                        metadata: '.PDF, 736KB',
                      },
                    ],
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section>
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default StabilityInstabilityPage;
